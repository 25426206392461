export const ErrorIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={48}
    height={48}
    fill='#E3204E'
  >
    <path
      opacity={0.5}
      d='M24 6c-4.621 0-7.54 5.174-13.376 15.523l-.728 1.29c-4.85 8.6-7.275 12.899-5.083 16.043S12.427 42 23.273 42h1.455c10.845 0 16.268 0 18.46-3.144s-.234-7.444-5.084-16.044l-.727-1.289C31.54 11.174 28.622 6 24 6Z'
    />
    <path d='M24 14.498a1.5 1.5 0 0 1 1.5 1.5v10a1.5 1.5 0 1 1-3 0v-10a1.5 1.5 0 0 1 1.5-1.5Z' />
    <path d='M24 33.998a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' />
  </svg>
);

export const SuccessIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={48}
    height={48}
    fill='#539E73'
  >
    <path
      opacity={0.5}
      d='M44.4 23.998c0 11.046-8.954 20-20 20-11.045 0-20-8.954-20-20s8.955-20 20-20c11.046 0 20 8.954 20 20Z'
    />
    <path d='M32.462 17.937a1.5 1.5 0 0 1 0 2.122l-10 10a1.5 1.5 0 0 1-2.122 0l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.94 2.94 4.469-4.47 4.47-4.47a1.5 1.5 0 0 1 2.12 0Z' />
  </svg>
);
