import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/CaseStudy/_Tabs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/CaseStudy/CaseStudy.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Comparison/Comparison.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/Contact/_Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/Faq/_List.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/FloatingItems/_InViewport.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/HeroApplication/HeroApplication.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Features/Features.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/ListWithIconAndCta/ListWithIconAndCta.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Reviews/Reviews.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Faq/Faq.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/StatsGrid/_Tiles.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/StatsGrid/StatsGrid.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Contact/Contact.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/FloatingItems/FloatingItems.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/Steps/_Timeline.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Team/Team.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/PersonIntroduction/PersonIntroduction.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_landing/ApplicationForm/ApplicationForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/Button/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/FloatingTilesAndImages/FloatingTilesAndImages.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/Copy/Copy.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Steps/Steps.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/Breadcrumbs/Breadcrumbs.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/ui/CopyToClipboard/CopyToClipboard.tsx");
